import axios from "axios";

const currentHost = `${window.location.protocol}//${window.location.hostname}`;

export const getApiUrl = () => {
  if (process.env.REACT_APP_USE_PROXY === "true") {
    return "/api/";
  }

  return process.env.REACT_APP_BACKEND;
};

export function getPayinApiUrl() {
  if (process.env.REACT_APP_USE_PROXY === "true") {
    return "/payin-api/";
  }
  return process.env.REACT_APP_BACKEND;
}

export function getPayoutApiUrl() {
  if (process.env.REACT_APP_USE_PROXY === "true") {
    return "/payout-api/";
  }
  return process.env.REACT_APP_PAYOUT_BACKEND;
}

export const buildRequestHeaders = (accessToken, includeCurrentTenant = false) => {
  const tenant = localStorage.getItem("pai");

  let headers = {
    "Access-Control-Allow-Origin": `${currentHost}`,
    "Access-Control-Allow-Access-Control-Allow-Credentials": "true",
    "Content-Type": "application/json",
    "Access-Control-Allow-Headers":
      "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
    Authorization: `Bearer ${accessToken}`,
    "X-TENANT-ID": "MORE",
  };

  if (includeCurrentTenant) {
    headers = { ...headers, "X-TENANT-ID": tenant };
  }

  return headers;
};

export const sendGETRequestToAPI = async (
  endpoint,
  accessToken,
  isIncludeCurrentTenant = false,
  requestParams
) => {
  // Usefull for get data for select input.
  return await axios.get(`${getApiUrl()}${endpoint}`, {
    headers: buildRequestHeaders(accessToken, isIncludeCurrentTenant),
    params: requestParams,
  });
};

export const validateIsMFARequired = async (accessToken) => {
  return axios.get(`${getPayoutApiUrl()}security/api/auth/mfa/isRequired`, {
    headers: buildRequestHeaders(accessToken),
  });
};
