import { useAuth0 } from "@auth0/auth0-react";
import jwt from "jwt-decode";
import { useEffect, useState } from "react";

const Auth0Helper = () => {
  const { getAccessTokenSilently } = useAuth0();

  const getEnabledCountries = async () => {
    const accessToken = await getAccessTokenSilently();

    const tokenInfo = jwt(accessToken);
    return tokenInfo.enabledCountries.split(",");
  };

  const getEnabledMerchantForPayment = async () => {
    const accessToken = await getAccessTokenSilently();

    const tokenInfo = jwt(accessToken);
    return tokenInfo.enabledMerchantsPayin.split(",");
  };

  const getScopes = async () => {
    const accessToken = await getAccessTokenSilently();

    const tokenInfo = jwt(accessToken);
    const scopes = tokenInfo.permissions;

    return scopes;
  };

  const validateUserScope = async (scopeNeededList) => {
    if (!scopeNeededList) {
      return true;
    }

    const userScopes = await getScopes();

    const valid = scopeNeededList ? scopeNeededList.some((x) => userScopes.includes(x)) : false;

    return valid;
  };

  const getPayoutEnabledCountries = async () => {
    const accessToken = await getAccessTokenSilently();

    const tokenInfo = jwt(accessToken);
    return tokenInfo.enabledCountriesPayOut.split(",");
  };

  return {
    getScopes,
    validateUserScope,
    getEnabledCountries,
    getEnabledMerchantForPayment,
    getPayoutEnabledCountries,
  };
};

export default Auth0Helper;

export const AUTH0_SCOPES = [
  "profile",
  "email",
  "payin:catalogue",
  "payin:getMerchantAggregator",
  "payin-commission:getCalculation",
  "payin-commission:saveCalculation",
  "payin-commission:deleteCalculation",
  "payin-commission:getCalculationRange",
  "payin-commission:saveCalculationRange",
  "payin-commission:deleteCalculationRange",
  "payin-commission:getCommission",
  "payin-commission:saveCommission",
  "payin-commission:deleteCommission",
  "payin-commission:getAllCalculation",
  "payin-commission:getCalculationById",
  "smartRouting:rule",
  "preTransaction:catalogue",
  "smartRouting:catalogue",
  "smartRouting:simulate",
  "smartRouting:tenant",
  "smartRouting:cron",
  "smartRouting_admin_access",
  "smartRouting_rule_access",
  "smartRouting_simulation_access",
  "smartRouting_setting_access",
  "smartRouting_cron_access",
  "pricing_adming_access",
  "pricing_commission_access",
  "pricing_calculation_access",
  "smartRouting_calculation_access",
  "smartRouting_commission_access",
  "smartRoutingApp_admin_access",
  "smartRouting_branchMatching_access",
];
