import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";

const PasswordExpiredComponent = (props) => {
  const { logout } = useAuth0();

  const handleLoginAgain = async () => {
    try {
      await logout();
    } catch (error) {
      console.warn(error);
    }
  };
  const toggle = () => {
    handleLoginAgain();
    props.setModal(!props.modal);
  };

  return (
    <div>
      <Modal isOpen={props.modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Password expired.</ModalHeader>
        <ModalBody>
          In the login, change password from "Forgot my password".
          <br />
          <a href="mailto:helpdesk@morepaymentevolution.com">
            Contact us - helpdesk@morepaymentevolution.com
          </a>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="primary" onClick={toggle}>
            Redirect to login
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PasswordExpiredComponent;
