export const changePropertiesName = (arr, mappings) => {
  const exportedData = arr.map((obj) => {
    const exportData = {};

    Object.keys(obj).forEach((key) => {
      const newKey = mappings[key] || key;

      if (Object.prototype.hasOwnProperty.call(mappings, key)) {
        exportData[newKey] = obj[key];
      }
    });

    return exportData;
  });

  return exportedData;
};

export const changePropertiesNameForObj = (arr, mappings, propertyName) => {
  const exportedData = arr.map((obj) => {
    const dynamicObj = obj[propertyName];
    const exportData = {};

    Object.keys(dynamicObj).forEach((key) => {
      const newKey = mappings[key] || key;

      if (Object.prototype.hasOwnProperty.call(mappings, key)) {
        exportData[newKey] = dynamicObj[key];
      }
    });

    return exportData;
  });

  return exportedData;
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const formatDate = (date, includeTime = false) => {
  if (includeTime) {
    return (
      [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join(
        "-"
      ) +
      " " +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(":")
    );
  }

  return [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join(
    "-"
  );
};

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

export const setMinimunTimeToDate = (date) => {
  const dateUTC = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));

  return dateUTC.toISOString().replace("T", " ").replace("Z", "");
};

export const setMaximunTimeToDate = (date) => {
  const dateUTC = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
  );

  return dateUTC.toISOString().replace("T", " ").replace("Z", "");
};

export const buildActiveComboOptions = () => {
  let options = [];

  options.push({ value: "true", label: "Active" });
  options.push({ value: "false", label: "Inactive" });

  return options;
};

export const buildEnabledCountriesOptions = (
  countryList,
  tokenEnabledCountries,
  returnValueAsObject = false
) => {
  let countriesOptions = countryList.filter((c) => tokenEnabledCountries.includes(c.paiIso2));

  if (returnValueAsObject) {
    return countriesOptions.map((c) => {
      return {
        value: c,
        label: c.paiDsc,
      };
    });
  }

  return countriesOptions.map((c) => {
    return {
      value: c.paiAbv,
      label: c.paiDsc,
    };
  });
};

export const buildPayoutCountriesOptions = (
  countryList,
  payoutCountries,
  returnValueAsObject = false
) => {
  let countriesOptions = countryList.filter((c) => payoutCountries.includes(c.paiIso3));

  let options = null;
  if (returnValueAsObject) {
    options = countriesOptions.map((c) => {
      return {
        value: c,
        label: c.paiDsc,
      };
    });
    if (payoutCountries.includes("CENTRAL")) {
      options.push({ value: { paiDsc: "Central" }, label: "CENTRAL" });
    }

    return options;
  }
  options = countriesOptions.map((c) => {
    return {
      value: c.paiAbv,
      label: c.paiDsc,
    };
  });

  if (payoutCountries.includes("CENTRAL")) {
    options.push({ value: "Central", label: "CENTRAL" });
  }

  return options;
};

export const getDateTimeNowForInput = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = `${now.getMonth() + 1}`.padStart(2, "0");
  const day = `${now.getDate()}`.padStart(2, "0");
  const hours = `${now.getHours()}`.padStart(2, "0");
  const minutes = `${now.getMinutes()}`.padStart(2, "0");
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const roundNumberByDecimals = (value, decimalCount) => {
  const d = Math.pow(10, decimalCount);

  return Math.round((value + Number.EPSILON) * d) / d;
};

export const getCurrentDateTimeStringFormat = () => {
  const now = new Date();

  const year = now.getFullYear().toString().padStart(4, "0");
  const month = (now.getMonth() + 1).toString().padStart(2, "0"); // El mes está basado en cero, por lo que se le suma 1
  const day = now.getDate().toString().padStart(2, "0");
  const hour = now.getHours().toString().padStart(2, "0");
  const minute = now.getMinutes().toString().padStart(2, "0");
  const second = now.getSeconds().toString().padStart(2, "0");
  const milliSecond = now.getMilliseconds().toString().padStart(2, "0");

  return year + month + day + hour + minute + second + milliSecond;
};

export const sortArrayByString = (array, fieldName) => {
  return array.sort((a, b) => {
    if (a[fieldName].trim() < b[fieldName].trim()) {
      return -1;
    }
    if (a[fieldName].trim() > b[fieldName].trim()) {
      return 1;
    }
    return 0;
  });
};
