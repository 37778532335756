import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userRol: [],
  permissions: [],
  isAdmin: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthData: (state, action) => {
      const decode = action.payload;
      const { userRol, permissions } = decode;

      state.userRol = userRol;
      state.permissions = permissions;

      state.isAdmin = permissions.includes("smartRouting_admin_access");
    },
    resetAuthData: (state) => {
      state = initialState;
    },
  },
});

export const { setAuthData, resetAuthData } = authSlice.actions;

export default authSlice.reducer;
