import logoMore from "../assets/img/logoMore.png";
import faviconMore from "../assets/img/faviconMore.png";

export const GLOBAL_CONSTANTS = {
  MORE_LOGO_PATH: logoMore,
  MORE_FAVICON: faviconMore,

  API_OAUTH_DOMAIN: process.env.REACT_APP_API_OAUTH_DOMAIN,
  API_OAUTH_CLIENT_ID: process.env.REACT_APP_OAUTH_CLIENT_ID,
  API_CALLBACK_URL: process.env.REACT_APP_CALLBACK_URL,
  //
  COEFICIENT_TYPE: 1,
  CONSTANT_TYPE: 2,
  //
  COMMISSION_BASE: 1,
  TRANSACTION_BASE: 2,
  REMAINING_BASE: 3,
  //
  AGGREGATOR_ROLE: 1,
  MERCHANT_ROLE: 2,

  PAYER_RISK_LOW: 1,
  PAYER_RISK_MEDIUM: 2,
  PAYER_RISK_HIGH: 3,
  //
  ENTER_KEY_CODE: 13,
};

export const CATALOGUE_URL_CONSTANTS = {
  getCountryListCatalogue: "smartrouting/catalogue/getCountries",
  getCountryListFilterCatalogue: "smartrouting/catalogue/getTenantCountries",
  getRuleTypeCatalogue: "smartrouting/catalogue/getAllRuleTypes",
  getProductCatalogue: "smartrouting/catalogue/getAllProducts",
  getCurrenciesCatalogue: "smartrouting/catalogue/getCurrencies?countryIso2=MORE",
  getCalculationsCatalogue: "pricing/commission/calculation",
  getCalculationTypesCatalogue: "pricing/catalogue/calculationType",
  getCalculationBasesCatalogue: "pricing/catalogue/calculationBase",
  getPaymentMethodsCatalogue: "pricing/paymentMethods",

  // PAYIN
  getMerchantAggregatorsCatalogue: "catalogue/merchantAggregator?page=0&size=999999&sort=name,asc",
  getAggregatorsCatalogue: "catalogue/selectOption/getListAggregator",
};

export const ALERT_TYPES = {
  success: "success",
  error: "danger",
};

export const ROLE_ENUM = [
  { id: 1, description: "Aggregator" },
  { id: 2, description: "Merchant" },
];

export const SOURCE_TYPES_ENUM = [
  { label: "API", value: 1 },
  { label: "FILE", value: 2 },
];

export const HTTP_STATUS_CODES = {
  NoContent: 204,
};
export const PAYER_RISK_ENUM = [
  { id: 1, description: "LOW" },
  { id: 2, description: "MEDIUM" },
  { id: 3, description: "HIGH" },
];

// COLORES
export const ColorOrangeMoreMT = "#f07c00";
export const ColorBlueMoreMT = "#051F44";
