import { configureStore } from "@reduxjs/toolkit";
import countryInfo from "./slices/countryInfo";
import authSlice from "./slices/authSlice";

export default configureStore({
  reducer: {
    countryInfo,
    authSlice,
  },
});
