import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./styles.scss";
import React, { useState, useEffect, lazy } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import CustomLoader from "components/shared/CustomLoader";
import { AlertProvider } from "components/shared/AlertContext";
import { useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
import { setAuthData } from "store/slices/authSlice";
import { resetAuthData } from "store/slices/authSlice";
import "./styles.scss";
import { Container } from "reactstrap";
import SmartRoutingLayout from "components/pages/SmartRoutingLayout";
import CustomNavbar from "components/shared/Navbars/Navbar";
import PasswordExpiredComponent from "components/shared/PasswordExpiredComponent";
import { validateIsMFARequired } from "utils/Api";

const MFA_REQUIRED_STATUS = "MFA_REQUIRED";

const App = () => {
  const [passwordExpired, setPasswordExpired] = useState(false);
  const [userEmail, setUserEmail] = useState();
  const [hasPermission, setHasPermission] = useState(false);
  const [isAppLoading, setIsAppLoading] = useState(true);

  const { isLoading, loginWithRedirect, user, isAuthenticated, getAccessTokenSilently, error } =
    useAuth0();
  const dispatch = useDispatch();

  const handleLoginAgain = async () => {
    try {
      await loginWithRedirect();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const login = async () => {
      try {
        if (!isLoading && !isAuthenticated && !user) {
          if (error && error.message?.includes("Your password has expired. Please reset it.")) {
            setUserEmail(error.message.split("|")[1]);
            setPasswordExpired(true);
            setIsAppLoading(false);
          } else {
            await handleLoginAgain();
          }
        } else if (isAuthenticated) {
          await setRolesAndPermission();
        }
      } catch (err) {
        console.error("Error during authentication:", err);
      }
    };

    login();
  }, [isLoading, isAuthenticated, user, error]);

  const loaderStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const clearTokenLocalStorage = () => {
    Object.keys(localStorage).forEach((key) => {
      if (key.includes("@@")) {
        localStorage.removeItem(key);
      }
    });
  };

  const setRolesAndPermission = async () => {
    try {
      const token = await getAccessTokenSilently({
        cacheMode: "cache-only", // Solo usa el token en caché si está disponible.
      });

      if (!token) {
        // hago limpieza de localStorage para volver a buscar token
        clearTokenLocalStorage();
        handleLoginAgain();
      }

      if (token) {
        const decode = jwtDecode(token);
        dispatch(setAuthData(decode));
        if (decode.permissions.includes("smartRouting_system_access")) {
          validateIsMFARequired(token)
            .then((result) => {
              if (result.data.status == MFA_REQUIRED_STATUS) {
                window.location.replace(process.env.REACT_APP_CALLBACK_URL);
              } else {
                setHasPermission(true);
                setIsAppLoading(false);
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
      } else {
        dispatch(resetAuthData());
      }
    } catch (error) {
      console.error("Error fetching token:", error);
      // Puedes manejar un reintento o redirigir al login si es necesario
      if (error.error === "login_required") {
        await handleLoginAgain();
      }
    }
  };

  if (isLoading)
    return (
      <div style={loaderStyle}>
        <CustomLoader />
      </div>
    );
  if (passwordExpired) {
    return (
      <PasswordExpiredComponent
        setModal={setPasswordExpired}
        userEmail={userEmail}
        modal={passwordExpired}
      />
    );
  }

  return (
    isAuthenticated &&
    (hasPermission ? (
      <BrowserRouter>
        <AlertProvider>
          <React.Suspense
            fallback={
              <div style={loaderStyle}>
                <CustomLoader />
              </div>
            }
          >
            <Switch>
              <Route path="/smart-routing" render={(props) => <SmartRoutingLayout {...props} />} />

              <Redirect to="/smart-routing/home" />
            </Switch>
          </React.Suspense>
        </AlertProvider>
      </BrowserRouter>
    ) : (
      <>
        <CustomNavbar isLoginView={true} />
        <Container
          fluid
          className="screen-height d-flex align-items-center justify-content-center"
          style={{ height: "85vh" }}
        >
          <h5>You don't have access to this application.</h5>
        </Container>
      </>
    ))
  );
};

export default App;
