import { lazy } from "react";

const BranchMatchingList = lazy(() =>
  import("components/pages/BranchMatching/BranchMatchingList/BranchMatchingList")
);
const CalculationEditor = lazy(() =>
  import("components/pages/Calculations/CalculationEditor/CalculationEditor")
);
const CalculationList = lazy(() =>
  import("components/pages/Calculations/CalculationList/CalculationList")
);
const CommissionBulkAction = lazy(() =>
  import("components/pages/Commissions/CommissionBulkAction/CommissionBulkAction")
);
const CommissionEditor = lazy(() =>
  import("components/pages/Commissions/CommissionEditor/CommissionEditor")
);
const CommissionList = lazy(() =>
  import("components/pages/Commissions/CommissionList/CommissionList")
);
const SmartRoutingLayout = lazy(() => import("components/pages/SmartRoutingLayout"));
const Home = lazy(() => import("components/pages/Home/Home"));
const Tenant = lazy(() => import("components/pages/Home/Tenant"));
const TenantList = lazy(() => import("components/pages/Administrator/TentantList/TenantList"));
const RuleEditor = lazy(() => import("components/pages/Rules/RuleEditor/RuleEditor"));
const RuleList = lazy(() => import("components/pages/Rules/RuleList/RuleList"));
const Simulation = lazy(() => import("components/pages/Simulation/Simulation"));
const CronExpressionEditor = lazy(() =>
  import("components/pages/Administrator/CronConfigExpression/CronExpressionEditor")
);

var routes = [
  {
    path: "/smart-routing",
    name: "Smart Routing",
    icon: "",
    component: SmartRoutingLayout,
    layout: "/smart-routing",
    showInSidebar: false,
  },
  {
    path: "/home",
    name: "Home",
    title: "Home",
    icon: "nc-icon nc-bank",
    component: Home,
    layout: "/smart-routing",
    showInSidebar: false,
  },
  {
    path: "/home/:tenant",
    name: "Tenant",
    title: "Tenant",
    icon: "nc-icon nc-bank",
    component: Tenant,
    layout: "/smart-routing",
    showInSidebar: false,
  },
  // Pricing Module
  {
    name: "Pricing",
    title: "Pricing",
    icon: "nc-icon far fa-money-bill-alt",
    showInSidebar: true,
    scopes: [
      "smartRoutingApp_admin_access",
      "pricing_admin_access",
      "pricing_calculation_access",
      "pricing_commission_access",
    ],
    childrens: [
      // Calculations
      {
        path: "/calculation/list",
        name: "Calculations",
        title: "Calculation List",
        icon: "nc-icon fas fa-calculator",
        component: CalculationList,
        layout: "/smart-routing",
        showInSidebar: true,
        scopes: [
          "smartRoutingApp_admin_access",
          "pricing_admin_access",
          "pricing_calculation_access",
        ],
      },
      {
        path: "/calculation/editor",
        name: "Calculations",
        title: "Calculation Editor",
        icon: "nc-icon fas fa-chart-pie",
        component: CalculationEditor,
        layout: "/smart-routing",
        showInSidebar: false,
      },
      {
        path: "/calculation/editor/:calculationId",
        name: "Calculations",
        title: "Calculation Editor",
        icon: "nc-icon fas fa-chart-pie",
        component: CalculationEditor,
        layout: "/smart-routing",
        showInSidebar: false,
      },
      // Commissions
      {
        path: "/commission/list",
        name: "Commissions",
        title: "Commission List",
        icon: "nc-icon fas fa-chart-pie",
        component: CommissionList,
        layout: "/smart-routing",
        showInSidebar: true,
        scopes: [
          "smartRoutingApp_admin_access",
          "pricing_admin_access",
          "pricing_commission_access",
        ],
      },
      {
        path: "/commission/editor",
        name: "Commissions",
        title: "Commission Editor",
        icon: "nc-icon fas fa-chart-pie",
        component: CommissionEditor,
        layout: "/smart-routing",
        showInSidebar: false,
      },
      {
        path: "/commission/editor/:commissionId",
        name: "Commissions",
        title: "Commission Editor",
        icon: "nc-icon fas fa-chart-pie",
        component: CommissionEditor,
        layout: "/smart-routing",
        showInSidebar: false,
      },
      {
        path: "/commission/bulk-action",
        name: "Commissions",
        title: "Commission Bulk Actions",
        icon: "nc-icon fas fa-chart-pie",
        component: CommissionBulkAction,
        layout: "/smart-routing",
        showInSidebar: false,
      },
    ],
  },

  // Smart Routing Module
  {
    name: "Smart Routing",
    title: "Smart Routing",
    icon: "nc-icon fas fa-project-diagram",
    showInSidebar: true,
    scopes: [
      "smartRoutingApp_admin_access",
      "smartRouting_admin_access",
      "smartRouting_rule_access",
      "smartRouting_simulation_access",
      "smartRouting_setting_access",
      "smartRouting_cron_access",
    ],
    childrens: [
      // Rules
      {
        path: "/rule/list",
        name: "Rules",
        title: "Rule List",
        icon: "nc-icon fas fa-ruler",
        component: RuleList,
        layout: "/smart-routing",
        showInSidebar: true,
        scopes: [
          "smartRoutingApp_admin_access",
          "smartRouting_admin_access",
          "smartRouting_rule_access",
        ],
      },
      {
        path: "/rule/editor",
        name: "Rules",
        title: "Rule Editor",
        icon: "nc-icon fas fa-ruler",
        component: RuleEditor,
        layout: "/smart-routing",
        showInSidebar: false,
      },
      {
        path: "/rule/editor/:ruleId",
        name: "Rules",
        title: "Rule Editor",
        icon: "nc-icon fas fa-ruler",
        component: RuleEditor,
        layout: "/smart-routing",
        showInSidebar: false,
      },
      // Simulation
      {
        path: "/simulation",
        name: "Simulation",
        title: "Simulation",
        icon: "nc-icon fas fa-cubes",
        component: Simulation,
        layout: "/smart-routing",
        showInSidebar: true,
        scopes: [
          "smartRoutingApp_admin_access",
          "smartRouting_admin_access",
          "smartRouting_simulation_access",
        ],
      },
      // Branches Matching
      {
        path: "/branches-matching/list",
        name: "Branches matching",
        title: "Branches matching",
        icon: "nc-icon fas fa-network-wired",
        component: BranchMatchingList,
        layout: "/smart-routing",
        showInSidebar: true,
        scopes: [
          "smartRoutingApp_admin_access",
          "smartRouting_admin_access",
          "smartRouting_branchMatching_access",
        ],
      },
      // Settings
      {
        path: "/merchant-setting",
        name: "Setting",
        title: "Setting",
        icon: "nc-icon fa fa-cogs",
        component: TenantList,
        layout: "/smart-routing",
        showInSidebar: true,
        scopes: [
          "smartRoutingApp_admin_access",
          "smartRouting_admin_access",
          "smartRouting_setting_access",
        ],
      },
      // Cron
      {
        path: "/cron-setting",
        name: "Cron",
        title: "Cron Setting",
        icon: "nc-icon fa fa-calendar",
        component: CronExpressionEditor,
        layout: "/smart-routing",
        showInSidebar: true,
        scopes: [
          "smartRoutingApp_admin_access",
          "smartRouting_admin_access",
          "smartRouting_cron_access",
        ],
      },
    ],
  },
];
export default routes;
