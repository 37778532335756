import { lazy, useState, useEffect, useRef, Suspense } from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, useLocation } from "react-router-dom";
import routes from "routes.js";
import { Container } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import jwtDecode from "jwt-decode";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAlert } from "components/shared/AlertContext";
import { ALERT_TYPES } from "utils/Constants";

const CustomNavbar = lazy(() => import("components/shared/Navbars/Navbar"));
const Sidebar = lazy(() => import("components/shared/Sidebar/Sidebar.js"));
const CustomLoader = lazy(() => import("components/shared/CustomLoader"));

var ps;

const SmartRoutingLayout = (props) => {
  const [backgroundColor, setBackgroundColor] = useState("black");
  const [activeColor, setActiveColor] = useState("info");
  const [isLoading, setIsLoading] = useState(false);

  const mainPanel = useRef();
  const location = useLocation();
  const history = useHistory();
  const showNotification = useAlert();

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.body.classList.toggle("perfect-scrollbar-on");
      }
    };
  }, []);

  useEffect(() => {
    mainPanel.current.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    const pai = localStorage.getItem("pai");
    if (!pai || !pai.trim()) {
      const fetchData = async () => {
        try {
          const token = await getAccessTokenSilently();
          const decodedToken = jwtDecode(token);
          const enabledCountries = decodedToken.enabledCountries;
          const countriesArray = enabledCountries.split(",");
          if (countriesArray && countriesArray.length > 0) {
            const firstCountry = countriesArray[0];
            history.push("/smart-routing/home/" + firstCountry);
          }
        } catch (error) {
          showNotification("You must select a country to continue operation.", ALERT_TYPES.error);
        }
      };
      fetchData();
    }
  }, [location]);

  const onShowLoader = (show) => {
    setIsLoading(show);
  };

  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        layoutName="smartRouting"
        headerLogo="https://www.morepaymentevolution.com/src/img/logo.svg"
        routes={routes}
        bgColor={backgroundColor}
        activeColor={activeColor}
      />
      <div className={`main-panel main-panel-big`} ref={mainPanel}>
        <CustomNavbar {...props} isLoginView={false} onShowLayoutLoader={onShowLoader} />
        <Container fluid className="content">
          <Suspense fallback={<CustomLoader className="loader-center-content" />}>
            <Switch>
              {routes.map((prop, key) => {
                return prop.component ? (
                  <Route
                    exact
                    path={prop.layout + prop.path}
                    component={prop.component}
                    key={key}
                  />
                ) : (
                  prop.childrens?.map((childProp, childKey) => {
                    return (
                      <Route
                        exact
                        path={childProp.layout + childProp.path}
                        component={childProp.component}
                        key={childKey}
                      />
                    );
                  })
                );
              })}
            </Switch>
          </Suspense>
        </Container>
      </div>
    </div>
  );
};

export default SmartRoutingLayout;
